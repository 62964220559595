import styled from 'styled-components'

import { TypeStyle, fromMd, untilMd } from '@syconium/little-miss-figgy'

import { DetailsSectionSidePaddedSection } from '../styles'

export const Layout = styled.div`
  display: flex;
`

export const Content = styled.div`
  flex: 1;
  min-width: 256px;

  ${fromMd} {
    margin: 0 auto;
    max-width: 350px;
  }
`

export const AddToBagButtonContainer = styled(DetailsSectionSidePaddedSection)`
  display: flex;
  margin-top: ${o => o.theme.spacing(6)};
`

export const SectionLabelWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;

  ${fromMd} {
    margin: 0;
  }
`
//TODO: introduce correct typography tokens for these sections
export const SectionTitle = styled(TypeStyle.BodyMicro)<{
  isInvalid?: boolean
}>`
  color: ${o => o.isInvalid && o.theme.color.deprecated.red.error};
  font-weight: 700;
  text-transform: uppercase;
`

export const SecondarySectionTitle = styled(TypeStyle.HeadlineExtraExtraSmall)`
  color: ${o => o.theme.color.deprecated.obsidian};
  font-weight: bold;

  ${fromMd} {
    font-size: 14px;
    letter-spacing: 0.15em;
    line-height: 1.7;
  }
`

export const SectionSubtitle = styled(TypeStyle.BodyMicro)`
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  margin-inline-start: 16px;
  font-weight: 400;
  ${fromMd} {
    margin-inline-start: 8px;
  }
`

export const ProductDetailsCopy = styled.blockquote<{
  secondary?: boolean
}>`
  ${TypeStyle.css.bodyMicro}
  color: ${o => o.secondary && o.theme.color.deprecated.obsidianLighter};
  padding: 0 ${o => o.theme.dimensions.layout.sidePadding.md}px;
`

export const DropdownWrapper = styled(DetailsSectionSidePaddedSection)`
  ${fromMd} {
    width: 100%;
  }
`

export const GiftAddToBagButtonContainer = styled(AddToBagButtonContainer)<{
  forceZeroMarginRight?: boolean
}>`
  position: relative;

  .Rise-add-to-cart-button {
    margin-right: ${o => !!o.forceZeroMarginRight && '0px !important'};
  }
`

export const RiseButtonContainer = styled(GiftAddToBagButtonContainer)`
  ${fromMd} {
    margin-top: 0px;
  }
`

export const Separator = styled.div`
  border-top: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  margin: 20px 0;
`

export const UnderlinedLink = styled.span`
  text-decoration: underline;
`

export const BoldText = styled.span`
  font-weight: bold;
`

export const SoldOutMessage = styled.span`
  color: ${o => o.theme.color.deprecated.red.error};
  white-space: nowrap;
`

export const SectionWrapper = styled.section<{ hasBorder?: boolean }>`
  margin-top: ${o => o.theme.spacing(6)};
  border-top: ${o =>
    o.hasBorder ? `1px solid ${o.theme.color.deprecated.stoneLight}` : undefined};
  padding-top: ${o => (o.hasBorder ? o.theme.spacing(6) : undefined)};

  &.multi-colors-section {
    margin-top: ${o => o.theme.spacing(5)};
  }

  &.size-section {
    margin-top: ${o => o.theme.spacing(2)};
  }

  &.embroidery-settings-section {
    padding-top: ${o => (o.hasBorder ? o.theme.spacing(5) : undefined)};
  }
  &.fit-section {
    margin-top: ${o => o.theme.spacing(4)};
  }

  &.personalize-section {
    margin-top: ${o => o.theme.spacing(4)};
  }

  ${untilMd} {
    padding: 0 ${o => o.theme.dimensions.layout.sidePadding.md}px;

    &.personalize-section {
      margin: 0 ${o => o.theme.dimensions.layout.sidePadding.md}px;
      margin-top: ${o => o.theme.spacing(4)};
      padding: 0 0;
      padding-top: ${o => (o.hasBorder ? o.theme.spacing(6) : undefined)};
    }

    &.embroidery-settings-section {
      margin: 0 ${o => o.theme.spacing(6)};
      margin-top: ${o => o.theme.spacing(6)};
      padding: 0 0;
      padding-top: ${o => (o.hasBorder ? o.theme.spacing(5) : undefined)};
    }
  }
`

/* Shared promo text component. Displayed in `GarmentDetails` for site-wide promo, `PortalGarmentAttributes` for portal promo */
export const PromoText = styled(TypeStyle.BodyNano)`
  color: ${o => o.theme.color.deprecated.red.error};
  font-weight: 700;
  padding: 24px 0 0 24px;
  text-transform: uppercase;

  ${fromMd} {
    padding: 24px 0 0 0;
  }
`
