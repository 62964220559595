import { LocalizationContainer } from '../../../brunswick/containers/localization/LocalizationContainer'

import { IdMeSection } from './IdMeSection'
import { StudentBeansSection } from './StudentBeansSection'

export const StudentDiscount = () => {
  const { region, isUSRegion, languageGroup } = LocalizationContainer.useContainer()

  if (isUSRegion) {
    return <IdMeSection />
  } else {
    return <StudentBeansSection region={region.id} language={languageGroup} />
  }
}
