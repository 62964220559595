import styled from 'styled-components'

import { pageLayoutMaxWidthPixels, untilMd } from '@syconium/little-miss-figgy'

import { RichTextSectionFragmentFragment } from '../../../../__generated__/graphql/catalog/graphql'
import { RichTextSection } from '../RichTextSection'
import { BasePageSection } from '../styles'

import type { ReactElement } from 'react'

const Content = styled.div`
  max-width: ${pageLayoutMaxWidthPixels}px;
  margin-left: auto;
  margin-right: auto;

  ${untilMd} {
    padding-left: ${o => o.theme.spacing(2)};
    padding-right: ${o => o.theme.spacing(2)};
  }
`

export const PageRichTextSection = (props: RichTextSectionFragmentFragment): ReactElement => {
  return (
    <BasePageSection as='div' hasVerticalMargin>
      <Content>
        <RichTextSection {...props} />
      </Content>
    </BasePageSection>
  )
}
