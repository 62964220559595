import styled from 'styled-components'

import {
  ResponsiveImage as LMFResponsiveImage,
  Skeleton,
  TypeStyle,
  fromMd,
  pageLayoutMaxWidthPixels,
} from '@syconium/little-miss-figgy'

export const Section = styled.section<{
  backgroundColor?: string
}>`
  background: ${o => o.backgroundColor};
`

export const ContentContainer = styled.div<{
  backgroundColor?: string
}>`
  max-width: ${pageLayoutMaxWidthPixels}px;
  margin-left: auto;
  margin-right: auto;

  background: ${o => o.backgroundColor};
  overflow: hidden;
  padding: 64px 16px;

  ${fromMd} {
    margin-bottom: 64px;
    padding: 84px 16px;
  }
`

export const TextContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  text-align: center;

  ${fromMd} {
    margin-bottom: 64px;
  }
`

export const SectionSubhead = styled(TypeStyle.BodySmall)`
  margin-bottom: 32px;
  margin-top: 16px;
  max-width: 432px;
`

export const Carousel = styled.div`
  position: relative;
`

export const CarouselArrowContainer = styled.div<{
  background?: string
}>`
  background-color: ${o => (o.background ? o.background : 'transparent')};
  display: none;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(calc(100% + 192px));

  ${fromMd} {
    display: flex;
  }
`

export const CarouselArrow = styled.button`
  border: none;
  height: 55px;
  padding: 8px;
  width: 55px;

  svg {
    transition: opacity 0.3s;
  }

  &:disabled svg {
    opacity: 0.5;
  }
`

export const CarouselArrowIcon: React.FC<{ scaleX?: number }> = ({ scaleX }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      stroke='currentColor'
      style={{
        transform: `scaleX(${scaleX})`,
      }}
    >
      <path
        strokeLinecap='square'
        strokeLinejoin='miter'
        strokeWidth='2'
        d='M10 19l-7-7m0 0l7-7m-7 7h18'
      />
    </svg>
  )
}

export const Testimonials = styled.ul`
  list-style: none;
  margin: 0;
  min-height: calc(80vw + 120px);
  padding: 0;
  position: relative;

  ${fromMd} {
    height: 574px;
    min-height: 0;
  }
`

export const Testimonial = styled.li<{
  before?: boolean
  current?: boolean
  next?: boolean
  after?: boolean
}>`
  display: flex;
  flex-direction: column;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transition: opacity 0.3s;

  &:only-child {
    position: relative;
  }

  ${o =>
    (o.current || o.next) &&
    `
    pointer-events: initial;
    opacity: 1;
  `}

  ${fromMd} {
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
`

export const TestimonialImageContainer = styled.div<{
  before?: boolean
  current?: boolean
  next?: boolean
  after?: boolean
  onlySlide?: boolean
}>`
  clip-path: inset(0 0 0 0);
  max-width: ${o => (o.onlySlide ? '100%' : '70vw')};
  transform: translateX(0);
  transition:
    transform 0.5s,
    clip-path 0.4s;

  ${o =>
    o.before &&
    `
    transform: translateX(-50%);
  `}

  ${o =>
    o.next &&
    `
    transform: translateX(calc(70vw + 16px));
    clip-path: inset(0 15% 41% 0);
  `}

  ${o =>
    o.after &&
    `
    transform: translateX(calc(70vw + 64px));
    clip-path: inset(0 15% 41% 0);
    transition-delay: 0.3s;
  `}

  ${fromMd} {
    display: flex;
    flex-shrink: 0;
    height: 574px;
    justify-content: center;
    max-width: none;
    order: 2;
    width: 574px;

    ${o =>
      o.next &&
      `
      transform: translateX(calc(100% + 16px));
    `}

    ${o =>
      o.after &&
      `
      transform: translateX(calc(150% + 16px));
    `}
  }
`

export const ResponsiveImage = styled(LMFResponsiveImage)<{
  before?: boolean
  current?: boolean
  next?: boolean
  after?: boolean
}>`
  clip-path: inset(0 0 0 0);
  max-width: 100%;
  object-fit: cover;
  transform-origin: top left;
  transition: transform 0.5s;

  ${o =>
    (o.after || o.next) &&
    `
    transform: scale(0.7);
  `}
`

export const TestimonialInfo = styled.figure<{
  before?: boolean
  current?: boolean
  next?: boolean
  after?: boolean
}>`
  margin: 0;
  transition:
    opacity 0.3s,
    transform 0.3s;

  ${o =>
    (o.before || o.after || o.next) &&
    `
    opacity:0;
  `}

  ${o =>
    o.before &&
    `
    transform: translateX(-10%);
  `}

  ${o =>
    (o.after || o.next) &&
    `
    transform: translateX(10%);
  `}

  ${fromMd} {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
    left: 0;
    max-width: max(33vw, 400px);
    position: absolute;
    top: 50%;
    transform: translate(min(6rem, 5vw), -50%);
    z-index: 1;

    figcaption,
    blockquote {
      transition: transform 0.4s;
    }

    figcaption {
      transition-delay: 0.01s;
    }

    ${o =>
      o.before &&
      `
      figcaption {
        transform: translateX(-64px);
      }

      blockquote {
        transform: translateX(-64px);
      }
    `}

    ${o =>
      (o.after || o.next) &&
      `
      figcaption {
        transform: translateX(64px);
      }

      blockquote {
        transform: translateX(64px);
      }
    `}
  }
`

export const Quote = styled.blockquote`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1.3;
  margin: 20px 0;

  ${fromMd} {
    font-size: 34px;
    line-height: 1.4;
    margin: 40px 0;
  }
`

export const Name = styled.p`
  letter-spacing: 1px;
  margin: 0 0 8px 0;
`

export const Occupation = styled.p`
  font-style: italic;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  text-transform: capitalize;
`

export const InstagramWrapper = styled.p`
  align-items: center;
  bottom: 0;
  display: flex;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0;
  padding: 16px;
  position: absolute;
  right: 0;
  text-transform: capitalize;

  svg {
    margin-right: 8px;
  }
`

export const FigcaptionTextSkeleton = styled(Skeleton)`
  ${fromMd} {
    max-width: 271px;
  }
`
