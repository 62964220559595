import styled, { keyframes } from 'styled-components'

import { Button, MediaAsContents, ResponsiveImage, fromMd } from '@syconium/little-miss-figgy'
import { BasePageSection } from '@syconium/magnolia/src/brunswick/components/page-sections/styles'

export const PageSection = styled(BasePageSection)`
  position: relative;
  height: 720px;
`

export const ContentOverlay = styled.div`
  ${fromMd} {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: 1fr 3fr 1fr;
  }
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: flex-start;
  padding: inherit;
  z-index: 2;
`

export const OverlayColor = styled.div<{ darkContent: boolean; customOpacity: number | undefined }>`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: ${o =>
    !o.darkContent
      ? o.theme.color.deprecated.black.primary
      : o.theme.color.deprecated.white.primary};
  opacity: ${o => (o.customOpacity ? o.customOpacity : `1`)};
  z-index: 1;
  transition: opacity, 0.6s;
  box-sizing: border-box;
  background-clip: content-box;
  padding: inherit;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const ImageContainer = styled.div<{ visible: boolean }>`
  position: absolute;
  padding: inherit;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${o => (!o.visible ? `0` : `1`)};
  transition: opacity 0.6s ease;
`
export const StyledImage = styled(ResponsiveImage)`
  z-index: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const TabButtonColumn = styled.div`
  display: flex;
  order: 3;
  align-self: center;
  gap: ${o => o.theme.spacing(2)};
  ${fromMd} {
    flex-direction: column;
    grid-column: 1;
    grid-row: 2;
    margin: 0 0 0 ${o => o.theme.spacing(10)};
    height: auto;
    width: 24px;
  }
  height: 24px;
  width: auto;
  margin-top: auto;
  margin-bottom: ${o => o.theme.spacing(10)};
`

export const TabSVG = styled.svg`
  max-width: 24px;
  max-height: 24px;
`

export const PauseSVG = styled.svg`
  max-width: 24px;
  max-height: 24px;
  viewbox: 0 0 24px 24px;
  fill: none;
`
export const StyledPath = styled.path<{ darkContentMobile: boolean; darkContentDesktop: boolean }>`
  fill-rule: evenodd;
  cliprule: evenodd;
  fill: ${o =>
    o.darkContentMobile ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  ${fromMd} {
    fill: ${o =>
      o.darkContentDesktop ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  }
`
export const DashOffsetAnimation = keyframes`
  from {
    stroke-dashoffset: 76px;
  }

  to {
    stroke-dashoffset: 6px;
  }
`

export const AnimatedProgressCircle = styled.circle<{
  isPaused: boolean
  darkContentMobile: boolean
  darkContentDesktop: boolean
}>`
  stroke-dasharray: 76px;
  cx: 12px;
  cy: 12px;
  r: 11px;
  fill: transparent;
  stroke: ${o =>
    o.darkContentMobile ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  ${fromMd} {
    stroke: ${o =>
      o.darkContentDesktop ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  }
  stroke-width: 2px;
  animation: ${DashOffsetAnimation} 8s linear;
  animation-play-state: ${o => (o.isPaused ? 'paused' : 'running')};
`

export const CompleteActiveCircle = styled.circle<{
  darkContentMobile: boolean
  darkContentDesktop: boolean
}>`
  stroke-dasharray: 76px;
  cx: 12px;
  cy: 12px;
  r: 11px;
  fill: transparent;
  stroke: ${o =>
    o.darkContentMobile
      ? o.theme.color.deprecated.obsidian
      : o.theme.color.deprecated.white.primary};
  ${fromMd} {
    stroke: ${o =>
      o.darkContentDesktop
        ? o.theme.color.deprecated.obsidian
        : o.theme.color.deprecated.white.primary};
  }
  stroke-width: 2px;
`
export const ActiveTabDot = styled.circle<{
  darkContentMobile: boolean
  darkContentDesktop: boolean
}>`
  cx: 12px;
  cy: 12px;
  r: 3px;
  fill: ${o =>
    o.darkContentMobile ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  ${fromMd} {
    fill: ${o =>
      o.darkContentDesktop ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  }
`

export const InactiveTabDot = styled.circle<{
  darkContentMobile: boolean
  darkContentDesktop: boolean
}>`
  cx: 12px;
  cy: 12px;
  r: 3px;
  fill: ${o =>
    o.darkContentMobile ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone};
  ${fromMd}{
    fill: ${o =>
      o.darkContentDesktop ? o.theme.color.deprecated.stoneDark : o.theme.color.deprecated.stone}};
  }
`

export const TabButton = styled.button`
  width: 24px;
  height: auto;
`

export const Header = styled.div<{ darkContent: boolean }>`
  ${fromMd} {
    line-height: 24px;
    font-size: 16px;
  }
  text-align: center;
  grid-column: 2;
  justify-self: center;
  align-self: center;
  font-weight: 700;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  color: ${o =>
    !o.darkContent
      ? o.theme.color.deprecated.white.primary
      : o.theme.color.deprecated.black.primary};
  font-size: 13px;
  line-height: 20px;
  margin-top: ${o => o.theme.spacing(7.5)};
  margin-bottom: auto;
`

export const SlideContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const SubheaderWrapper = styled.div`
  grid-column: 2;
  grid-row: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${o => o.theme.spacing(8)};
`
export const Subheader = styled.div<{ isCurrentSlide: boolean; darkContent: boolean }>`
  ${fromMd} {
    font-size: ${o => (o.isCurrentSlide ? `36px` : `18px`)};
    line-height: ${o => (o.isCurrentSlide ? `48px` : `27px`)};
    letter-spacing: ${o => (o.isCurrentSlide ? `5.4px` : `2.7px`)};
    transform: none;
  }
  text-align: center;
  color: ${o =>
    o.isCurrentSlide
      ? !o.darkContent
        ? o.theme.color.deprecated.white.primary
        : o.theme.color.deprecated.black.primary
      : !o.darkContent
      ? o.theme.color.deprecated.stone
      : o.theme.color.deprecated.gray.darkest};
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 3px;
  transform: scale(${o => (o.isCurrentSlide ? `1` : `0.8`)});
  font-weight: 700;
  text-transform: uppercase;
  transition:
    color 0.6s ease,
    transform 0.6s ease,
    font-size 0.6s ease,
    line-height 0.6s ease,
    letter-spacing 0.6s ease;
`

export const SlideCtaButton = styled(Button)`
  margin-top: ${o => o.theme.spacing(7.5)};
`

export const MediaAsContentsInheritPadding = styled(MediaAsContents)`
  padding: inherit;
`
