import { TitleDefault, TitleExtraLarge, TitleLarge, TitleMedium } from './styles'
import { Props } from './types'

export const PageTileSectionTitle: React.FC<Props> = ({ titleTextSize, ...props }) => {
  switch (titleTextSize) {
    case 'h1':
      return (
        <TitleExtraLarge asTag={titleTextSize} {...props}>
          {props.children}
        </TitleExtraLarge>
      )
    case 'h2':
      return (
        <TitleLarge asTag={titleTextSize} {...props}>
          {props.children}
        </TitleLarge>
      )
    case 'h3':
      return (
        <TitleMedium asTag={titleTextSize} {...props}>
          {props.children}
        </TitleMedium>
      )
    default:
      return (
        <TitleDefault asTag={titleTextSize} {...props}>
          {props.children}
        </TitleDefault>
      )
  }
}
