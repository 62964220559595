import { useQuery } from '@apollo/client'
import { useEffect, useMemo } from 'react'

import { degenderProductGroupTitle } from '@syconium/magnolia/src/lib/utils/domain'
import { CarouselProductSummary } from '@syconium/magnolia/src/types/figs'

import { reportClientError } from '../../../app/_components/chrome/scripts/DataDogRumScript'
import { QueryStatus } from '../../../lib/hooks/types'

import { GET_COLLECTION_SECTION_PRODUCTS } from './queries'

export function useCollectionSectionQuery({
  collectionHandle,
  productCountLimit,
}: {
  collectionHandle: string
  productCountLimit: number | null
}) {
  const { data, error, called, loading } = useQuery(GET_COLLECTION_SECTION_PRODUCTS, {
    variables: { handle: collectionHandle, first: productCountLimit ?? 75 },
  })

  const products: CarouselProductSummary[] = useMemo(() => {
    const results =
      data?.collection?.items?.nodes?.map(item => {
        const pg = item.productGroup
        const p = item
        const result: CarouselProductSummary = {
          category: p.category,
          colorDisplayName: p.colorInfo?.name,
          colorRawName: p.colorInfo?.rawName,
          colorCount: pg.numColors,
          currency: p.defaultVariant.priceDetails?.price?.currency,
          discountPrice: p.defaultVariant.priceDetails?.discountPrice?.amount,
          price: p.defaultVariant.priceDetails?.price?.amount,
          handle: p.handle ?? '',
          id: p.id,
          image: p.images?.nodes[0]?.source,
          priceRange: pg.priceRange ?? undefined,
          productGroupHandle: pg.handle,
          shopifyId: p.shopifyId,
          title: pg.title ? degenderProductGroupTitle(pg.title) : '',
        }
        return result
      }) ?? []

    return results
  }, [data?.collection?.items?.nodes])

  useEffect(() => {
    if (error) {
      reportClientError({
        error,
        context: {
          scope: 'useCollectionSectionQuery',
          label: 'CollectionSection collection section products query failed',
          collectionHandle,
        },
      })
    }
  }, [collectionHandle, error])

  const status = useMemo<Exclude<QueryStatus, 'idle'>>(() => {
    if (loading || !called) return 'pending'
    if (error) 'rejected'
    return 'resolved'
  }, [called, error, loading])

  return {
    products,
    status,
  }
}
