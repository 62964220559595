import styled, { css } from 'styled-components'

import { TypeStyle, fromMd } from '@syconium/little-miss-figgy'
import { sectionSideGapDesktop } from '@syconium/magnolia/src/brunswick/components/page-sections/styles'

type TitleBaseProps = {
  textAlign?: string
  mobileTextAlign?: string
  hasHorizontalMargin?: boolean
}

const TitleBase = css<TitleBaseProps>`
  color: ${o => o.theme.color.deprecated.obsidian};
  margin-bottom: 16px;
  padding: 0 ${o => o.hasHorizontalMargin && o.theme.dimensions.layout.sidePadding.md + 'px'};
  text-align: ${props => props.mobileTextAlign ?? 'inherit'};

  ${fromMd} {
    padding: 0 ${o => o.hasHorizontalMargin && sectionSideGapDesktop + 'px'};
    text-align: ${props => props.textAlign ?? 'inherit'};
    margin-bottom: 24px;
  }
`

export const TitleDefault = styled(TypeStyle.HeadlineSmall)<TitleBaseProps>`
  ${TitleBase}
`

export const TitleMedium = styled(TypeStyle.HeadlineMedium)<TitleBaseProps>`
  ${TitleBase}
`

export const TitleLarge = styled(TypeStyle.HeadlineLarge)<TitleBaseProps>`
  ${TitleBase}
`

export const TitleExtraLarge = styled(TypeStyle.HeadlineExtraLarge)<TitleBaseProps>`
  ${TitleBase}
`
