import * as React from 'react'

import { Instagram } from '@syconium/little-miss-figgy'
import { usePageSectionContext } from '@syconium/magnolia/src/components/page-sections/PageSectionContext'
import { SectionTitle } from '@syconium/magnolia/src/components/pages/products/styles'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { PageTestimonialSection as Props } from '../../../../types/graphql'
import { useFixturesContext } from '../../../containers/fixtures'

import {
  Carousel,
  CarouselArrow,
  CarouselArrowContainer,
  CarouselArrowIcon,
  ContentContainer,
  InstagramWrapper,
  Name,
  Occupation,
  Quote,
  ResponsiveImage,
  Section,
  SectionSubhead,
  Testimonial,
  TestimonialImageContainer,
  TestimonialInfo,
  Testimonials,
  TextContainer,
} from './styles'

export const PageTestimonialSection: React.FC<Props & { backgroundColor?: string }> = ({
  title,
  subtitle,
  testimonials,
  backgroundColor,
}) => {
  const {
    testimonials: { nextSlideButtonLabel, prevSlideButtonLabel },
  } = useFixturesContext()

  const numSlides = testimonials?.length ?? 0
  const lastSlideIndex = numSlides - 1

  const { pageSectionIndex: pageSectionIndex } = usePageSectionContext()

  const [x, setX] = React.useState(0)
  const [y, setY] = React.useState(0)
  const [lock, setLock] = React.useState(false)
  const [slideIndex, setSlideIndex] = React.useState(0)
  const getSlideProps = (index: number) => {
    return {
      before: slideIndex > index,
      current: slideIndex === index,
      next: slideIndex + 1 === index,
      after: slideIndex + 1 < index,
    }
  }

  const moveSlide = (indexDelta: number): void => {
    setSlideIndex(Math.min(lastSlideIndex, Math.max(0, slideIndex + indexDelta)))
  }

  const handleTouchStart = (e: React.TouchEvent): void => {
    setX(e.changedTouches[0]?.screenX ?? 0)
    setY(e.changedTouches[0]?.screenY ?? 0)
  }

  const handleTouchMove = (e: React.TouchEvent): void => {
    const dx = (e.changedTouches[0]?.screenX ?? 0) - x
    const dy = (e.changedTouches[0]?.screenY ?? 0) - y
    if (Math.abs(dx) < 30 || Math.abs(dy) > Math.abs(dx) || lock) return
    setLock(true)
    moveSlide(dx >= 0 ? -1 : 1)
  }

  const handleTouchEnd = (_: React.TouchEvent): void => {
    setLock(false)
  }

  return (
    <Section backgroundColor={backgroundColor}>
      <ContentContainer>
        <TextContainer>
          <SectionTitle>{title}</SectionTitle>
          <SectionSubhead data-testid='testimonials-carousel-subhead'>{subtitle}</SectionSubhead>
        </TextContainer>

        {numSlides > 0 && (
          <Carousel
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove}
          >
            <Testimonials>
              {testimonials?.map((testimonial, index) => {
                return (
                  <React.Fragment key={index}>
                    <Testimonial {...getSlideProps(index)}>
                      <TestimonialImageContainer
                        {...getSlideProps(index)}
                        onlySlide={numSlides === 1}
                      >
                        <ResponsiveImage
                          {...getSlideProps(index)}
                          alt={testimonial.personName}
                          src={testimonial.image}
                          aspectRatios={{
                            sm: null,
                            md: null,
                          }}
                          loading='default'
                          widths={{ unit: 'vw', sm: 100, md: 50 }}
                        />

                        {testimonial.instagram && (
                          <InstagramWrapper>
                            <Instagram /> @{testimonial.instagram}
                          </InstagramWrapper>
                        )}
                      </TestimonialImageContainer>
                      <TestimonialInfo {...getSlideProps(index)}>
                        <Quote>{testimonial.quote}</Quote>
                        <figcaption>
                          <Name>{testimonial.personName}</Name>
                          <Occupation>{testimonial.occupation}</Occupation>
                        </figcaption>
                      </TestimonialInfo>
                    </Testimonial>
                  </React.Fragment>
                )
              })}
            </Testimonials>
            {numSlides > 1 && (
              <CarouselArrowContainer
                background={backgroundColor}
                {...trackEvent({
                  category: 'page-testimonial-section',
                  action: 'change testimonial slide',
                  pageSectionIndex,
                  pageSectionName: title,
                })}
              >
                <CarouselArrow
                  aria-label={prevSlideButtonLabel}
                  disabled={slideIndex <= 0}
                  onClick={() => moveSlide(-1)}
                >
                  <CarouselArrowIcon scaleX={1} />
                </CarouselArrow>
                <CarouselArrow
                  aria-label={nextSlideButtonLabel}
                  disabled={slideIndex >= lastSlideIndex}
                  onClick={() => moveSlide(1)}
                >
                  <CarouselArrowIcon scaleX={-1} />
                </CarouselArrow>
              </CarouselArrowContainer>
            )}
          </Carousel>
        )}
      </ContentContainer>
    </Section>
  )
}
