import { FC, useMemo, useState } from 'react'

import { ProductCarousel } from '@syconium/magnolia/src/brunswick/components/ProductCarousel'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import {
  CollectionSectionFragmentFragment,
  StyledImage,
} from '../../../__generated__/graphql/catalog/graphql'
import { ProductCarouselSkeleton } from '../../../brunswick/components/ProductCarousel/Skeleton/ProductCarouselSkeleton'
import { ContentfulStyledImage } from '../../contentful/ContentfulStyledImage/ContentfulStyledImage'
import { ContentfulStyledLink } from '../../contentful/ContentfulStyledLink/ContentfulStyledLink'
import { ContentfulStyledText } from '../../contentful/ContentfulStyledText/ContentfulStyledText'
import { usePageSectionContext } from '../PageSectionContext'

import {
  ActiveName,
  Body,
  CarouselWrapper,
  ColorFilterButton,
  ColorFilters,
  ContentWrapper,
  Heading,
  LinkWrapper,
  TitleWrapper,
} from './styles'
import { useCollectionSectionQuery } from './useCollectionSectionQuery'

export type CollectionSectionProps = CollectionSectionFragmentFragment

export const CollectionSection: FC<CollectionSectionProps> = ({
  collectionHandle,
  featuredImage,
  featuredInlineVideoDesktop,
  featuredInlineVideoMobile,
  hideColors,
  hidePrice,
  links,
  productCountLimit,
  titleLockupImage,
  subtitleText,
  titleText,
  featuredImages,
  colorsForFilters,
}) => {
  const computedLinks = links ?? []

  const { products, status } = useCollectionSectionQuery({
    collectionHandle,
    productCountLimit,
  })

  const [activeFilterIndex, setActiveFilterIndex] = useState(0)
  const { pageSectionIndex } = usePageSectionContext()
  const activeFeaturedImage: CollectionSectionFragmentFragment['featuredImage'] =
    featuredImages && featuredImages.length > 0 && featuredImages[activeFilterIndex]
      ? featuredImages[activeFilterIndex] ?? null
      : featuredImage ?? null

  const featuredVideo =
    featuredInlineVideoDesktop || featuredInlineVideoMobile
      ? {
          featuredInlineVideoDesktop,
          featuredInlineVideoMobile: featuredInlineVideoMobile ?? undefined,
          alt: titleText?.title ?? '',
        }
      : null

  const visibleTilesDesktop = 4
  const fullBleed = !!activeFeaturedImage || !!featuredVideo

  const filteredProducts = useMemo(() => {
    if (!colorsForFilters) return products
    return products.filter(
      props => props.colorDisplayName === colorsForFilters[activeFilterIndex]?.name
    )
  }, [activeFilterIndex, colorsForFilters, products])

  if (status === 'rejected' || (status === 'resolved' && products.length === 0)) return null

  return (
    <Body>
      {!!(titleText || computedLinks.length > 0) && (
        <Heading>
          {(!!titleText || !!subtitleText) && (
            <TitleWrapper>
              {titleLockupImage &&
              (titleLockupImage.imageDesktop || titleLockupImage.imageMobile) ? (
                <ContentfulStyledImage
                  widths={{ unit: 'vw', sm: 100, md: 100 }}
                  transparentBackground={true}
                  {...(titleLockupImage as StyledImage)}
                />
              ) : null}
              {/** TODO: Properly type titleAlignment* in Contentful Gateway. */}
              {!!titleText && <ContentfulStyledText {...titleText} />}
              {!!subtitleText && <ContentfulStyledText {...subtitleText} />}
            </TitleWrapper>
          )}

          {computedLinks.length > 0 ? (
            <LinkWrapper>
              {computedLinks.map(link => {
                return (
                  <ContentfulStyledLink
                    key={`${link.url}:${link.text}`}
                    {...link}
                    analyticsContext={{
                      ...trackEvent({
                        category: 'collection-section',
                        action: 'click shop all cta',
                        label: link.url,
                        pageSectionName: titleText?.title ?? collectionHandle,
                        pageSectionIndex,
                        correspondingAsset:
                          activeFeaturedImage?.imageDesktop ??
                          featuredVideo?.featuredInlineVideoDesktop ??
                          undefined,
                      }),
                    }}
                  />
                )
              })}
            </LinkWrapper>
          ) : null}
        </Heading>
      )}

      {colorsForFilters && colorsForFilters.length > 0 ? (
        <ColorFilters>
          {colorsForFilters.map((props, index) => (
            <button
              onClick={() => setActiveFilterIndex(index)}
              key={props.name}
              {...trackEvent({
                category: 'collection-section',
                action: 'click color filter',
                label: props.name,
                pageSectionName: titleText?.title ?? collectionHandle,
                pageSectionIndex,
                correspondingAsset: activeFeaturedImage?.imageDesktop ?? undefined,
              })}
            >
              <ColorFilterButton
                size={'large'}
                hexCodes={props?.hexCodes}
                selected={index === activeFilterIndex}
              />
            </button>
          ))}
          <ActiveName>{colorsForFilters[activeFilterIndex]?.name}</ActiveName>
        </ColorFilters>
      ) : null}

      <ContentWrapper>
        <CarouselWrapper>
          {status === 'pending' ? (
            <ProductCarouselSkeleton fullBleed={fullBleed} />
          ) : (
            <ProductCarousel
              fullBleed={fullBleed}
              hideColors={hideColors ?? undefined}
              hidePrice={hidePrice ?? undefined}
              featuredImage={activeFeaturedImage}
              featuredVideo={featuredVideo}
              products={filteredProducts}
              visibleTilesDesktop={visibleTilesDesktop}
              analyticsContext={{ pageSectionName: titleText?.title ?? collectionHandle }}
            />
          )}
        </CarouselWrapper>
      </ContentWrapper>
    </Body>
  )
}
