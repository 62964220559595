import styled from 'styled-components'

import {
  fromMd,
  mdStartPixels,
  pageLayoutMaxWidthPixels,
  timingSlower,
} from '@syconium/little-miss-figgy'

export const Main = styled.main``

export const Section = styled.section`
  max-width: ${pageLayoutMaxWidthPixels}px;
  margin-left: auto;
  margin-right: auto;
`

export const PrimaryAndAsideSection = styled(Section)<{
  fadeOut?: boolean
}>`
  display: grid;
  grid-template-columns: minmax(min(100%, ${mdStartPixels}px), 1fr);
  row-gap: ${props => props.theme.spacing(6)};
  column-gap: ${props => props.theme.spacing(6)};
  margin-bottom: ${props => props.theme.spacing(5)};

  ${fromMd} {
    margin-bottom: ${props => props.theme.spacing(10)};
    grid-template-columns: minmax(0px, 2fr) minmax(0px, 1fr);
    padding-left: ${props => props.theme.spacing(10)};
    padding-right: ${props => props.theme.spacing(10)};
  }

  transition: opacity ${timingSlower} ease;
  opacity: ${o => (o.fadeOut ? '0.65' : '1')};
`

export const PrimaryColumnDiv = styled.div`
  grid-column: span 1;
`

export const AsideColumnDiv = styled.div`
  grid-column: span 1;
`

export const SectionTitle = styled.h2`
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 2px;
  line-height: 11px;
  text-align: center;
  text-transform: uppercase;

  ${fromMd} {
    font-size: 18px;
  }
`
