import styled from 'styled-components'

import { fromMd, pageLayoutMaxWidth } from '@syconium/little-miss-figgy'
import {
  sectionGapDesktop,
  sectionGapMobile,
} from '@syconium/magnolia/src/brunswick/components/page-sections/styles'

export const Body = styled.section<{ hasVerticalMargin?: boolean }>`
  margin: 0 auto;
  max-width: ${pageLayoutMaxWidth};
  padding-bottom: ${o => o.hasVerticalMargin && sectionGapMobile + 'px'};

  ${fromMd} {
    padding-bottom: ${o => o.hasVerticalMargin && sectionGapDesktop + 'px'};
  }
`
