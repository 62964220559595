import styled, { keyframes } from 'styled-components'

import {
  ImageShrink,
  ResponsiveImage,
  TypeStyle,
  easeInOutCubic,
  fromMd,
  fromXl,
  untilMd,
} from '@syconium/little-miss-figgy'
import { ButtonBar } from '@syconium/magnolia/src/brunswick/components/atoms/ButtonBar'
import {
  sectionGapDesktop,
  sectionGapMobile,
} from '@syconium/magnolia/src/brunswick/components/page-sections/styles'

import { PlayButton } from '../../../lib/shared-styles'
import {
  PageHeroSectionTextPlacementDesktop,
  PageHeroSectionTextPlacementMobile,
} from '../../../types/graphql/contentful-components/page-hero-section'

import { ScrollDownArrow } from './ScrollDownArrow'

// Mobile, textPlacementMobile='below', image is 320x270
const arrowHeight = 15
const arrowPadMobile = 42
const arrowPadDesktop = 56

export const Container = styled.section<{
  isFullscreenHeight: boolean
  textPlacementMobile: PageHeroSectionTextPlacementMobile
}>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: ${o => (o.textPlacementMobile === 'bottom' ? 'flex-end' : 'center')};
  position: relative;

  min-height: 100vw;
  ${o => (o.textPlacementMobile !== 'below' ? 'min-height: min(77vh,136vw)' : null)};
  ${o => (o.isFullscreenHeight ? 'min-height: 100svh' : null)};

  ${fromMd} {
    justify-content: center;
    min-height: ${o => (o.isFullscreenHeight ? '100svh' : '50vw')};
  }

  ${fromXl} {
    min-height: ${o => (o.isFullscreenHeight ? '100svh' : '800px')};
  }
`

export const ImageWrap = styled.div<{
  backgroundColor: string | null
  shouldCollapse: boolean
  textPlacementMobile: PageHeroSectionTextPlacementMobile
}>`
  background: ${o => o.backgroundColor};
  height: 100%;
  position: absolute;
  width: 100%;

  ${o =>
    o.textPlacementMobile === 'below' &&
    `
    ${fromMd} {
      padding-top: 0;
      position: absolute;
    }
    `}

  img,
  video {
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: top;
    font-family: 'object-fit: cover;';
    position: absolute;
    top: 0;
    width: 100%;

    ${fromMd} {
      object-fit: cover;
      height: 100%;
    }
  }
`

export const ImageWrapOverlay = styled.div<{
  overlayColor: string | null
}>`
  background: ${o => o.overlayColor};
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export const MobileAssetOverlayWhenBelow = styled.div`
  display: none;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 2 * 24px); /* 24px gap on each side */
  z-index: ${o => o.theme.zIndex.foregroundContent};

  ${fromMd} {
    display: none;
  }
`

export const Eyebrow = styled(TypeStyle.Eyebrow)`
  margin: 0 auto 8px;
  max-width: 352px;
  text-transform: uppercase;

  ${fromMd} {
    margin-bottom: 16px;
    max-width: 70%;
  }
`

export const Title = styled(TypeStyle.HeadlineMedium)<{
  isTitleSerif: boolean | null
  isTitleItalic: boolean | null
}>`
  font-family: ${({ isTitleSerif, theme: { typography } }) =>
    isTitleSerif
      ? typography.deprecated.font.family.editorial
      : typography.deprecated.font.family.primary};
  font-style: ${o => o.isTitleItalic && 'italic'};
  margin: 0 auto;
  max-width: 532px;

  ${fromMd} {
    max-width: 80%;
  }
`

export const Subtitle = styled(TypeStyle.BodyLarge)`
  margin: 4px auto 0;
  max-width: 85%;

  ${fromMd} {
    max-width: 70%;
    margin-top: 20px;
  }
`

export const TextWrap = styled.div<{
  mobileTextColor: string
  desktopTextColor: string
  textContentMaxWidthDesktop: number | null
  textContentMaxWidthMobile: number | null
  textPlacementDesktop: PageHeroSectionTextPlacementDesktop
  textPlacementMobile: PageHeroSectionTextPlacementMobile
}>`
  align-items: center;
  box-sizing: border-box;
  color: ${o => o.mobileTextColor};
  display: flex;
  flex-direction: column;
  padding: ${o => (o.textPlacementMobile === 'below' ? '16px' : '73px')}
    ${o => o.theme.dimensions.layout.sidePadding.md}px;
  position: relative;
  text-align: center;
  width: 100%;

  ${untilMd} {
    ${Eyebrow},
    ${Title},
    ${Subtitle} {
      max-width: ${o => o.textContentMaxWidthMobile && `${o.textContentMaxWidthMobile}px`};
    }

    > picture {
      width: 100%;
    }
  }

  ${fromMd} {
    color: ${o => o.desktopTextColor};
    align-self: ${o =>
      o.textPlacementDesktop === 'left'
        ? 'flex-start'
        : o.textPlacementDesktop === 'right'
        ? 'flex-end'
        : 'center'};
    padding: 24px 40px;
    width: ${o => (o.textPlacementDesktop === 'center' ? '100%' : '50%')};

    ${Eyebrow},
    ${Title},
    ${Subtitle} {
      max-width: ${o => o.textContentMaxWidthDesktop && `${o.textContentMaxWidthDesktop}px`};
    }
  }

  picture {
    display: contents;
  }
`

export const MobileBelowTitleImage = styled(ImageShrink)<{
  titleImageMaxWidthMobile: number | null
}>`
  display: block;
  margin: 0 auto;
  max-width: ${o => o.titleImageMaxWidthMobile && `${o.titleImageMaxWidthMobile}px`};
  width: 100%;

  & + ${PlayButton} {
    margin-top: 30px;
  }
`

export const TextWrapTitleImage = styled(ResponsiveImage)<{
  titleImageMaxWidthDesktop: number | null
  titleImageMaxWidthMobile: number | null
}>`
  display: block;
  margin: 0 auto 16px;

  ${untilMd} {
    max-width: ${o => o.titleImageMaxWidthMobile && `${o.titleImageMaxWidthMobile}px`};
    width: 100%;
  }

  ${fromMd} {
    display: block;
    margin: 0 auto 24px;
    max-width: ${o => o.titleImageMaxWidthDesktop && `${o.titleImageMaxWidthDesktop}px`};
    width: 80%;
  }

  ${Eyebrow} + & {
    margin-top: 8px;

    ${fromMd} {
      margin-top: 16px;
    }
  }
`

export const TextWrapPlayButton = styled(PlayButton)<{
  hasMobileModalVideo: boolean | null
}>`
  display: ${o => (o.hasMobileModalVideo ? 'none' : 'initial')};
  margin: 22px auto 4px;

  ${fromMd} {
    display: initial;
    margin: 8px auto 4px;
  }
`

export const TextWrapPlayButtonMobile = styled(PlayButton)`
  display: initial;
  margin: 16px auto 4px;

  ${fromMd} {
    display: none;
  }
`

export const TopArrowSpacer = styled.div`
  display: none;
  height: ${arrowHeight + arrowPadMobile}px;

  ${fromMd} {
    display: initial;
    height: ${arrowHeight + arrowPadDesktop}px;
  }
`

const arrowDip = keyframes`
  0% {
    transform: translateY(0);
  }
  80% {
    transform: translateY(0);
  }
  90% {
    transform: translateY(16px);
  }
  100% {
    transform: translateY(0);
  }
`

export const StyledScrollDownArrow = styled(ScrollDownArrow)<{
  textColor: string
  hideDownArrow: boolean | null
  textPlacementMobile?: PageHeroSectionTextPlacementMobile
}>`
  display: ${o => o.textPlacementMobile === 'below' && 'none'};
  animation: ${arrowDip} 4s infinite ${easeInOutCubic};
  bottom: 0;
  color: ${o => o.textColor};
  padding-bottom: ${arrowPadMobile}px;
  position: absolute;
  visibility: ${o => (o.hideDownArrow ? 'hidden' : 'visible')};

  ${fromMd} {
    display: initial;
    padding-bottom: ${arrowPadDesktop}px;
  }
`

export const StyledButtonList = styled.ul`
  display: flex;
`

export const StyledButtonListItem = styled.li`
  min-width: 155px;
`

export const StyledButtonBar = styled(ButtonBar)`
  margin-top: 16px;

  ${fromMd} {
    margin-top: 24px;
  }
`

export const VimeoContainer = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
`

export const TextBelowSection = styled.section`
  position: relative;
`

export const HeroBottomMargin = styled.div`
  margin-bottom: ${sectionGapMobile}px;

  ${fromMd} {
    margin-bottom: ${sectionGapDesktop / 2}px;
  }
`

export const PlayButtonWrapper = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 24px;
  ${fromMd} {
    margin-top: 40px;
    margin-bottom: 40px;
  }
`
