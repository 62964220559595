import styled from 'styled-components'

import { fromMd, pageLayoutMaxWidth } from '@syconium/little-miss-figgy'

const gapMobile = 40
const gapDesktop = 60

export const Body = styled.section`
  max-width: ${pageLayoutMaxWidth};
  padding: 0 24px ${gapMobile}px;
  text-align: center;

  ${fromMd} {
    padding-bottom: ${gapDesktop}px;
  }
`
