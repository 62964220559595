import { MouseEvent as ReactMouseEvent, useCallback, useRef, useState } from 'react'

export function usePlayPrimaryVideo() {
  const [primaryVideoHasPlayed, setPrimaryVideoHasPlayed] = useState<boolean>(false)

  const markPrimaryVideoAsPlayed = useCallback(() => {
    setPrimaryVideoHasPlayed(true)
  }, [])

  const primaryVideoRef = useRef<HTMLVideoElement>(null)

  /**
   * On iOS when in Low Power Mode, videos will not autoplay and the UI
   * displays a play button to allow the user to deliberately play the video.
   * Because the other elements sit in front of the video player in this
   * component, the user is unable to tap the play button. This function
   * provides a means to play the video when the user attempts to tap the play
   * button but inadvertently taps an element sitting in front of it.
   */
  const playPrimaryVideo = useCallback(
    (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
      const eventTarget = event.target as HTMLElement | null
      if (eventTarget?.tagName === 'BUTTON') return
      if (primaryVideoHasPlayed) return
      if (!primaryVideoRef.current) return
      primaryVideoRef.current.play()
    },
    [primaryVideoHasPlayed]
  )

  return {
    markPrimaryVideoAsPlayed,
    playPrimaryVideo,
    primaryVideoRef,
  }
}
