import { FC } from 'react'

import { tileAspectRatioByLayoutType } from '@syconium/magnolia/src/brunswick/constants'

import { CenteredTileLayout } from '../../../brunswick/components/CenteredTileLayout'
import {
  PageTileSectionTile as PageTileSectionTileTypes,
  PageTileSection as Props,
} from '../../../types/graphql/contentful-components/page-tile-section'

import { PageTileSectionTile } from './PageTileSectionTile'
import { Body } from './styles'
import { PageTileSectionTitle } from './Title/Title'

export const PageTileSection: FC<Props> = ({
  name,
  numColsDesktop,
  numColsMobile,
  tileAspectRatio,
  textAlign,
  textPlacement,
  tiles,
  title,
  titleTextSize,
  variantDesktop,
  variantMobile,
  titleAlignmentDesktop,
  titleAlignmentMobile,
  hasHorizontalMargin,
  hasVerticalMargin,
  hasTileGaps,
}) => {
  const tileAspectRatioOverride =
    tileAspectRatio && tileAspectRatio > 0 ? tileAspectRatio : undefined

  return (
    <Body hasVerticalMargin={hasVerticalMargin}>
      {title && (
        <PageTileSectionTitle
          titleTextSize={titleTextSize}
          textAlign={titleAlignmentDesktop}
          mobileTextAlign={titleAlignmentMobile}
          hasHorizontalMargin={hasHorizontalMargin}
        >
          {title}
        </PageTileSectionTitle>
      )}
      <CenteredTileLayout
        keyFromTile={(tile: PageTileSectionTileTypes, index) => `${tile.id}-${index}`}
        layoutVariantDesktop={variantDesktop}
        layoutVariantMobile={variantMobile}
        liftedButtons={textPlacement === 'below'}
        minTileWidth={211}
        hasHorizontalMargin={hasHorizontalMargin}
        hasVerticalMargin={hasVerticalMargin}
        hasTileGaps={hasTileGaps}
        renderTile={(tile: PageTileSectionTileTypes, { layoutVariant }) => (
          <PageTileSectionTile
            textAlign={textAlign}
            textPlacement={textPlacement}
            {...tile}
            aspectRatio={tileAspectRatioOverride ?? tileAspectRatioByLayoutType[layoutVariant]}
            roundCorners={layoutVariant === 'carousel' || layoutVariant === 'slider'}
            sectionName={name}
            title={title}
            visibleTilesDesktop={numColsDesktop}
            visibleTilesMobile={numColsMobile}
            hasVerticalMargin={hasVerticalMargin}
          />
        )}
        tiles={tiles}
        visibleTilesDesktop={numColsDesktop}
        visibleTilesMobile={numColsMobile}
      />
    </Body>
  )
}
