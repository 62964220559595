import React from 'react'

import { TypeStyle } from '@syconium/little-miss-figgy'

import { PageTitleSection as Props } from '../../../types/graphql'

import { Body } from './styles'

export const PageTitleSection: React.FC<Props> = ({ title }) => {
  return (
    <Body data-testid='page-title-section-body'>
      <TypeStyle.HeadlineExtraSmall isUppercase textTransform='uppercase' asTag='h5'>
        {title}
      </TypeStyle.HeadlineExtraSmall>
    </Body>
  )
}
