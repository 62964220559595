import styled from 'styled-components'

import { TypeStyle, fromMd, pageLayoutMaxWidth, untilMd } from '@syconium/little-miss-figgy'
import {
  sectionGapDesktop,
  sectionGapMobile,
  sectionSideGapDesktop,
} from '@syconium/magnolia/src/brunswick/components/page-sections/styles'

import { TitleAlignment } from '../../../types/graphql'

export const Body = styled.div`
  margin: 0 auto;
  padding-bottom: ${sectionGapMobile}px;

  ${fromMd} {
    max-width: ${pageLayoutMaxWidth};
    padding-bottom: ${sectionGapDesktop}px;
  }
`

export const Title = styled(TypeStyle.HeadlineSmall)<{
  alignmentDesktop?: TitleAlignment
  alignmentMobile?: TitleAlignment
}>`
  margin-bottom: 24px;
  color: ${o => o.theme.color.text.primary.on.background};

  ${untilMd} {
    padding: 0 ${o => o.theme.dimensions.layout.sidePadding.md}px;
    text-align: ${o => o.alignmentMobile ?? 'left'};
  }

  ${fromMd} {
    padding: 0 ${sectionSideGapDesktop}px;
    text-align: ${o => o.alignmentDesktop ?? 'left'};
  }
`
