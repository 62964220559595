import Head from 'next/head'

import { ResponsiveImage } from '@syconium/little-miss-figgy'

export const HeroImage: React.FC<{
  imageDesktop: string | null
  imageMobile: string | null
  title: string
  isCriticalImage: boolean
}> = ({ imageMobile, imageDesktop, title, isCriticalImage }) => {
  const imageSrc = imageDesktop ?? imageMobile

  if (!imageSrc) return null

  const srcs =
    imageDesktop && imageMobile
      ? {
          sm: imageMobile,
          md: imageDesktop,
        }
      : undefined

  return (
    <ResponsiveImage
      src={imageSrc}
      alt={title}
      aspectRatios={{
        sm: null,
        md: null,
      }}
      loading={isCriticalImage ? 'default' : 'lazy'}
      fetchpriority={isCriticalImage ? 'high' : undefined}
      widths={{ unit: 'vw', sm: 100, md: 100 }}
      srcs={srcs}
      renderPreloadLink={
        isCriticalImage
          ? preloadLink => {
              return <Head>{preloadLink}</Head>
            }
          : undefined
      }
    />
  )
}
