import { ComponentProps, FC, useState } from 'react'
import { ThemeProvider } from 'styled-components'

import { ResponsiveImage, useDarkTheme, useSupportsHover } from '@syconium/little-miss-figgy'

import { VimeoVideo } from '../../../brunswick/components/VimeoVideo'
import { trackEvent } from '../../../lib/analytics'
import { useResponsiveVideoSrc } from '../../../lib/hooks/useResponsiveVideoSrc'
import { usePageSectionContext } from '../PageSectionContext'

import {
  CtaButton,
  ImageWrap,
  TileContentWrapper,
  TileHeadline,
  TileInformationBackground,
  TileInformationContent,
  TileInformationDescription,
  TileInformationHeadline,
  TileInformationHeadlineText,
  ToggleHeadlineIcon,
} from './styles'

interface TileProps {
  loading: ComponentProps<typeof ResponsiveImage>['loading']
  widths: ComponentProps<typeof ResponsiveImage>['widths']
  image: string | undefined
  headline: string | null
  description: string | null
  vimeoId: number | null
  ctaHref: string | null
  ctaText: string | null
  analyticsContext: { pageSectionName?: string; tileIndex: number }
}

export const Tile: FC<TileProps> = ({
  loading,
  widths,
  image,
  vimeoId,
  description,
  headline,
  ctaText,
  ctaHref,
  analyticsContext,
}) => {
  const video = useResponsiveVideoSrc({
    inlineVimeoIdDesktop: vimeoId,
    inlineVimeoIdMobile: vimeoId,
    preferredVideoPlatform: 'vimeo',
  })
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const supportsHover = useSupportsHover()
  const { pageSectionIndex: pageSectionIndex } = usePageSectionContext()

  const onDisclosureButtonClick = supportsHover
    ? null
    : () => {
        setIsExpanded(!isExpanded)
      }

  // This is assuming the image is dark, technically merch would have to provide that info.
  const darkTheme = useDarkTheme()

  return (
    <ImageWrap aspectRatio={110 / 149}>
      <ThemeProvider theme={darkTheme}>
        {video?.src ? (
          <VimeoVideo
            autoplay
            lazy
            thumbnail={
              image ? (
                <ResponsiveImage
                  alt=''
                  loading={loading}
                  aspectRatios={{
                    sm: 110 / 149,
                    md: 110 / 149,
                  }}
                  widths={widths}
                  src={image}
                />
              ) : null
            }
            videoSrc={video?.src}
            includePauseButton={true}
          />
        ) : image && !vimeoId && ctaHref && ctaText ? (
          <>
            <ResponsiveImage
              alt=''
              loading={loading}
              aspectRatios={{
                sm: 110 / 149,
                md: 110 / 149,
              }}
              widths={widths}
              src={image}
            />
            <TileContentWrapper>
              <TileHeadline>{headline}</TileHeadline>
              <CtaButton
                as='a'
                href={ctaHref}
                variant={'black-on-white-no-border'}
                {...trackEvent({
                  category: 'page-editorial-carousel-section',
                  action: `click cta on tile ${analyticsContext.tileIndex}`,
                  pageSectionName: analyticsContext.pageSectionName,
                  pageSectionIndex,
                  correspondingAsset: image,
                })}
              >
                {ctaText}
              </CtaButton>
            </TileContentWrapper>
          </>
        ) : image && !vimeoId ? (
          <ResponsiveImage
            alt=''
            loading={loading}
            aspectRatios={{
              sm: 110 / 149,
              md: 110 / 149,
            }}
            widths={widths}
            src={image}
          />
        ) : null}
      </ThemeProvider>
      {description && headline && !ctaHref ? (
        <TileInformationBackground expanded={isExpanded}>
          <TileInformationContent>
            <TileInformationHeadline
              expanded={isExpanded}
              onClick={onDisclosureButtonClick}
              analyticsContext={{
                category: 'page-editorial-carousel-section',
                action: `toggle expand on tile ${analyticsContext.tileIndex}`,
                pageSectionName: analyticsContext.pageSectionName,
                pageSectionIndex,
                correspondingAsset: image,
              }}
            >
              <ToggleHeadlineIcon />
              <TileInformationHeadlineText>{headline}</TileInformationHeadlineText>
            </TileInformationHeadline>
            <TileInformationDescription>{description}</TileInformationDescription>
          </TileInformationContent>
        </TileInformationBackground>
      ) : null}
    </ImageWrap>
  )
}
