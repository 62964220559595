import { FC, useState } from 'react'
import React from 'react'

import { MediaAsContents } from '@syconium/little-miss-figgy'
import { InteractiveSlide as InteractiveSlideType } from '@syconium/magnolia/src/types/graphql/contentful-components/interactive-slide'
import { PageInteractiveSlideSection as Props } from '@syconium/magnolia/src/types/graphql/contentful-components/page-interactive-slide-section'

import { MaybeSpaLinkWrapper } from '../../../lib/adapters/next-routing-service/MaybeSpaLinkWrapper'
import { trackEvent } from '../../../lib/analytics'
import { usePageSectionContext } from '../PageSectionContext'

import {
  ActiveTabDot,
  AnimatedProgressCircle,
  CompleteActiveCircle,
  ContentOverlay,
  Header,
  ImageContainer,
  InactiveTabDot,
  MediaAsContentsInheritPadding,
  OverlayColor,
  PageSection,
  PauseSVG,
  SlideContentWrapper,
  SlideCtaButton,
  StyledImage,
  StyledPath,
  Subheader,
  SubheaderWrapper,
  TabButton,
  TabButtonColumn,
  TabSVG,
} from './styles'

export const PageInteractiveSlideSection: FC<Props> = ({
  title,
  interactiveSlides,
  isFullBleedDesktop,
  hasVerticalMargin,
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)
  const currentSlide = interactiveSlides[currentSlideIndex]
  const [isPaused, setIsPaused] = useState(false)
  const { pageSectionIndex: pageSectionIndex } = usePageSectionContext()
  const handleTabClick = (index: number) => {
    if (index === currentSlideIndex) {
      setIsPaused(!isPaused)
    } else {
      setIsPaused(true)
      setCurrentSlideIndex(index)
    }
  }

  const incrementSlide = () => {
    setCurrentSlideIndex(prevIndex => (prevIndex + 1) % interactiveSlides.length)
  }
  const handleContentMouseOver = (index: number) => {
    setCurrentSlideIndex(index)
    setIsPaused(true)
  }
  const handleContentMouseLeave = () => {
    setIsPaused(false)
  }
  const handleSubheaderClickMobile = (index: number) => {
    setCurrentSlideIndex(index)
    setIsPaused(true)
  }

  return (
    <PageSection
      isFullBleedDesktop={isFullBleedDesktop}
      hasVerticalMargin={hasVerticalMargin}
      {...trackEvent({
        category: 'page-interactive-slide-section',
        action: 'any',
        pageSectionName: title,
        pageSectionIndex,
      })}
    >
      {interactiveSlides.map((slide, index) => (
        <ImageContainer key={index} visible={currentSlideIndex === index}>
          <MediaAsContentsInheritPadding lessThan='md'>
            <OverlayColor
              darkContent={currentSlide?.contentColorMobile === 'dark'}
              customOpacity={currentSlide?.overlayOpacity}
            >
              <StyledImage
                loading='lazy'
                src={'https:' + slide.imageMobile}
                aspectRatios={{
                  sm: slide.aspectRatioMobile ?? 1,
                  md: slide.aspectRatioDesktop ?? 1,
                }}
                widths={{ unit: 'vw', sm: 100, md: 100 }}
                alt={slide.altText}
              />
            </OverlayColor>
          </MediaAsContentsInheritPadding>

          <MediaAsContentsInheritPadding greaterThanOrEqual='md'>
            <OverlayColor
              darkContent={currentSlide?.contentColorDesktop === 'dark'}
              customOpacity={currentSlide?.overlayOpacity}
            >
              <StyledImage
                loading='lazy'
                aspectRatios={{
                  sm: slide.aspectRatioMobile ?? 1,
                  md: slide.aspectRatioDesktop ?? 1,
                }}
                src={'https:' + slide.imageDesktop}
                widths={{ unit: 'vw', sm: 100, md: 100 }}
                alt={slide.altText}
              />
            </OverlayColor>
          </MediaAsContentsInheritPadding>
        </ImageContainer>
      ))}
      <ContentOverlay>
        <TabButtonColumn>
          {interactiveSlides.map((slide, index) => (
            <TabButton
              onFocus={() => setIsPaused(true)}
              onBlur={() => setIsPaused(false)}
              role='tab'
              id={'tab' + index}
              aria-controls={'tabpanel' + index}
              title={slide.title}
              key={index}
              onMouseDown={() => handleTabClick(index)}
            >
              {index === currentSlideIndex ? (
                <TabSVG>
                  {isPaused ? (
                    <PauseSVG>
                      <StyledPath
                        darkContentMobile={slide.contentColorMobile === 'dark'}
                        darkContentDesktop={slide.contentColorDesktop === 'dark'}
                        d='M10 7C10.5523 7 11 7.44772 11 8V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V8C9 7.44772 9.44772 7 10 7Z'
                      />
                      <StyledPath
                        darkContentMobile={slide.contentColorMobile === 'dark'}
                        darkContentDesktop={slide.contentColorDesktop === 'dark'}
                        d='M14 7C14.5523 7 15 7.44772 15 8V16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16V8C13 7.44772 13.4477 7 14 7Z'
                      />
                    </PauseSVG>
                  ) : (
                    <ActiveTabDot
                      darkContentMobile={slide.contentColorMobile === 'dark'}
                      darkContentDesktop={slide.contentColorDesktop === 'dark'}
                    />
                  )}
                  <CompleteActiveCircle
                    darkContentMobile={slide.contentColorMobile === 'dark'}
                    darkContentDesktop={slide.contentColorDesktop === 'dark'}
                  />
                  <AnimatedProgressCircle
                    darkContentMobile={slide.contentColorMobile === 'dark'}
                    darkContentDesktop={slide.contentColorDesktop === 'dark'}
                    onAnimationEnd={incrementSlide}
                    isPaused={isPaused}
                  />
                </TabSVG>
              ) : (
                <TabSVG>
                  <InactiveTabDot
                    darkContentMobile={slide.contentColorMobile === 'dark'}
                    darkContentDesktop={slide.contentColorDesktop === 'dark'}
                  />
                </TabSVG>
              )}
            </TabButton>
          ))}
        </TabButtonColumn>
        <MediaAsContents lessThan='md'>
          <Header darkContent={currentSlide?.contentColorMobile === 'dark'}>{title}</Header>
        </MediaAsContents>
        <MediaAsContents greaterThanOrEqual='md'>
          <Header darkContent={currentSlide?.contentColorDesktop === 'dark'}>{title}</Header>
        </MediaAsContents>
        <SubheaderWrapper>
          {interactiveSlides.map((slide: InteractiveSlideType, index: number) => (
            <SlideContentWrapper key={slide.id}>
              <MediaAsContents greaterThanOrEqual='md'>
                <Subheader
                  onMouseOver={() => handleContentMouseOver(index)}
                  onMouseLeave={() => handleContentMouseLeave()}
                  role='tabpanel'
                  id={'tabpanel' + index}
                  aria-labelledby={'tab' + index}
                  isCurrentSlide={currentSlideIndex === index}
                  onFocus={() => setIsPaused(true)}
                  onBlur={() => setIsPaused(false)}
                  tabIndex={0}
                  darkContent={currentSlide?.contentColorDesktop === 'dark'}
                >
                  {slide.title}
                </Subheader>
              </MediaAsContents>
              <MediaAsContents lessThan='md'>
                <Subheader
                  role='tabpanel'
                  id={'tabpanel' + index}
                  aria-labelledby={'tab' + index}
                  isCurrentSlide={currentSlideIndex === index}
                  onFocus={() => setIsPaused(true)}
                  onBlur={() => setIsPaused(false)}
                  tabIndex={0}
                  darkContent={currentSlide?.contentColorMobile === 'dark'}
                  onClick={() => handleSubheaderClickMobile(index)}
                >
                  {slide.title}
                </Subheader>
              </MediaAsContents>

              {currentSlideIndex === index && (
                <>
                  <MediaAsContents lessThan='md'>
                    <MaybeSpaLinkWrapper href={slide.ctaLink}>
                      {
                        <SlideCtaButton
                          {...trackEvent({
                            category: 'page-interactive-slide-section',
                            action: 'click',
                            label: 'slide cta button',
                            value: slide.ctaLink,
                            pageSectionName: title,
                            pageSectionIndex,
                            correspondingAsset: currentSlide?.imageMobile,
                          })}
                          as='a'
                          href={slide.ctaLink}
                          onFocus={() => setIsPaused(true)}
                          variant={
                            slide.contentColorMobile === 'dark'
                              ? 'white-on-black-no-border'
                              : 'black-on-white-no-border'
                          }
                        >
                          {slide.ctaText}
                        </SlideCtaButton>
                      }
                    </MaybeSpaLinkWrapper>
                  </MediaAsContents>
                  <MediaAsContents greaterThanOrEqual='md'>
                    <MaybeSpaLinkWrapper href={slide.ctaLink}>
                      {
                        <SlideCtaButton
                          {...trackEvent({
                            category: 'page-interactive-slide-section',
                            action: 'click',
                            label: 'slide cta button',
                            value: slide.ctaLink,
                            pageSectionName: title,
                            pageSectionIndex,
                            correspondingAsset: currentSlide?.imageDesktop,
                          })}
                          as='a'
                          href={slide.ctaLink}
                          onFocus={() => setIsPaused(true)}
                          variant={
                            slide.contentColorDesktop === 'dark'
                              ? 'white-on-black-no-border'
                              : 'black-on-white-no-border'
                          }
                        >
                          {slide.ctaText}
                        </SlideCtaButton>
                      }
                    </MaybeSpaLinkWrapper>
                  </MediaAsContents>
                </>
              )}
            </SlideContentWrapper>
          ))}
        </SubheaderWrapper>
      </ContentOverlay>
    </PageSection>
  )
}
