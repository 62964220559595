import styled, { css } from 'styled-components'

import { TypeStyle, fromMd, untilMd } from '@syconium/little-miss-figgy'
import { BasePageSection } from '@syconium/magnolia/src/brunswick/components/page-sections/styles'

import { PlayButton } from '../../../lib/shared-styles'
import {
  PageBannerSectionTextAlign,
  PageBannerSectionTextPlacementDesktop,
  PageBannerSectionTextPlacementMobile,
} from '../../../types/graphql/contentful-components/page-banner-section'

const aspectRatioMobileShort = 320 / 253
const aspectRatioMobileTall = 320 / 486
const aspectRatioDesktopSide = 600 / 475
const aspectRatioDesktopSideFullBleed = 720 / 500
const aspectRatioDesktopCenter = 1200 / 475
const aspectRatioDesktopCenterFullBleed = 1440 / 500
const getContentAlignment = (textPlacementMobile: string) => {
  if (textPlacementMobile === 'below') return 'flex-start'
  if (textPlacementMobile === 'center') return 'center'
  return 'flex-end'
}

export const PageSection = styled(BasePageSection)``

export const Body = styled.div<{
  backgroundColorDesktop: string | null
  backgroundColorMobile: string | null
  textPlacementDesktop: PageBannerSectionTextPlacementDesktop
  textPlacementMobile: PageBannerSectionTextPlacementMobile
}>`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: ${o => getContentAlignment(o.textPlacementMobile)};
  position: relative;

  ${untilMd} {
    background-color: ${o => o.backgroundColorMobile};
  }

  ${fromMd} {
    background-color: ${o => o.backgroundColorDesktop};
    flex-direction: ${o => (o.textPlacementDesktop === 'left' ? 'row-reverse' : 'row')};
    justify-content: ${o => o.textPlacementDesktop === 'center' && 'center'};
  }
`

export const Eyebrow = styled.h3<{
  textPlacementMobile: PageBannerSectionTextPlacementMobile
}>`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.15em;
  line-height: 1.25em;
  margin-bottom: 16px;
  text-transform: uppercase;

  ${fromMd} {
    font-size: 18px;
    margin-top: 0;
  }
`

interface AssetWrapperProps {
  isFullBleedDesktop: boolean | null
  isTallAssetMobile: boolean | null
  overlayColorDesktop: string | null
  overlayColorMobile: string | null
  textPlacementDesktop: PageBannerSectionTextPlacementDesktop
  textPlacementMobile: PageBannerSectionTextPlacementMobile
}

const assetWrapperColorOverlay = css<AssetWrapperProps>`
  &::after {
    background: ${o => o.overlayColorMobile};
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;

    ${fromMd} {
      background: ${o => o.overlayColorDesktop};
      display: ${o => (o.textPlacementDesktop === 'center' ? 'block' : 'none')};
    }
  }
`

export const AssetWrapper = styled.div<AssetWrapperProps>`
  display: block;
  overflow: hidden;
  padding-top: min(
    ${o =>
      (1 /
        (o.textPlacementMobile !== 'below' || o.isTallAssetMobile
          ? aspectRatioMobileTall
          : aspectRatioMobileShort)) *
      100}%,
    ${o => (o.textPlacementMobile !== 'below' || o.isTallAssetMobile ? '550px' : '350px')}
  );
  position: relative;
  width: 100%;

  > picture > img,
  > video {
    height: 100%;
    left: 0;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${fromMd} {
    padding-top: max(
      min(
        ${o =>
          (1 /
            (o.textPlacementDesktop === 'center'
              ? o.isFullBleedDesktop
                ? aspectRatioDesktopCenterFullBleed
                : aspectRatioDesktopCenter
              : (o.isFullBleedDesktop ? aspectRatioDesktopSideFullBleed : aspectRatioDesktopSide) /
                0.5)) *
          100}%,
        ${o => (o.isFullBleedDesktop ? '750px' : '700px')}
      ),
      360px
    );
    width: ${o => o.textPlacementDesktop !== 'center' && '50%'};
  }

  ${o => (o.overlayColorMobile || o.overlayColorDesktop) && assetWrapperColorOverlay}
`

export const Links = styled.div<{
  textAlignMobile: PageBannerSectionTextAlign | null
  textAlignDesktop: PageBannerSectionTextAlign | null
}>`
  margin: 16px -12px -22px;
  text-align: ${o => o.textAlignMobile || 'center'};

  ${untilMd} {
    > :nth-child(2) {
      margin-right: ${o => o.textAlignMobile === 'right' && '0'};
    }

    > :first-child {
      margin-left: ${o => o.textAlignMobile === 'left' && '0'};
    }
  }

  ${fromMd} {
    margin-top: 26px;
    text-align: ${o => o.textAlignDesktop || 'center'};

    > :first-child {
      margin-left: ${o => o.textAlignDesktop === 'left' && '0'};
      margin-right: ${o => o.textAlignDesktop === 'right' && '0'};
    }

    > :nth-child(2) {
      margin-right: ${o => o.textAlignDesktop === 'right' && '0'};
    }
  }

  a {
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.15em;
    line-height: 23px;
    margin: 0 12px 22px;
    position: relative;
    text-align: center;
    text-transform: uppercase;

    &::after {
      border-bottom: 2px solid currentColor;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
  gap: 12px;
`

export const MobileAssetOverlayWhenBelow = styled.div<{
  textPlacementMobile: PageBannerSectionTextPlacementMobile
}>`
  display: ${o => o.textPlacementMobile !== 'below' && 'none'};
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 2 * 24px); /* 24px gap on each side */
  z-index: ${o => o.theme.zIndex.foregroundContent};

  ${fromMd} {
    display: none;
  }
`

export const Paragraph = styled.p`
  ${TypeStyle.css.bodyDefault};
`

export const StyledContentItemsWrapper = styled.div<{
  textPlacementDesktop: PageBannerSectionTextPlacementDesktop
  textPlacementMobile: PageBannerSectionTextPlacementMobile
  endsInLink: boolean
}>`
  box-sizing: border-box;

  padding: ${o => (o.textPlacementMobile === 'bottom' ? '0 16px 73px 16px' : '0 16px')};

  ${untilMd} {
    padding-top: ${o => o.textPlacementMobile === 'below' && '48px'};
    width: 100%;
    position: ${o => o.textPlacementMobile !== 'below' && 'absolute'};
    padding-bottom: ${o =>
      o.textPlacementMobile === 'bottom'
        ? '73px'
        : o.textPlacementMobile === 'below' && o.endsInLink
        ? '24px'
        : o.textPlacementMobile === 'below'
        ? '48px'
        : null};
  }

  ${fromMd} {
    position: ${o => (o.textPlacementDesktop === 'center' ? 'absolute' : 'relative')};
    padding: 0 24px;
  }

  width: ${o => (o.textPlacementDesktop === 'center' ? '100%' : '50%')};

  > * {
    margin-left: auto;
    margin-right: auto;
  }
`

export const TextOverlay = styled.div<{
  textColorDesktop: string | null
  textColorMobile: string | null
  textContentMaxWidthDesktop: number | null
  textContentMaxWidthMobile: number | null
  textPlacementDesktop: PageBannerSectionTextPlacementDesktop
  textPlacementMobile: PageBannerSectionTextPlacementMobile
  textAlignMobile: PageBannerSectionTextAlign | null
  textAlignDesktop: PageBannerSectionTextAlign | null
}>`
  box-sizing: border-box;
  color: ${o => (o.textPlacementMobile !== 'below' || o.theme.name == 'dark') && o.textColorMobile};
  letter-spacing: 0.6px;
  margin-top: ${o => o.textPlacementMobile === 'below' && '40px'};
  padding: ${o => (o.textPlacementMobile === 'bottom' ? '0 24px 73px 24px' : '0 24px 40px')};
  position: ${o => o.textPlacementMobile !== 'below' && 'absolute'};
  text-align: ${o => o.textAlignMobile || 'center'};

  ${untilMd} {
    > .configurable-width {
      max-width: ${o => o.textContentMaxWidthMobile && `${o.textContentMaxWidthMobile}px`};
    }
  }

  ${fromMd} {
    color: ${o => o.textColorDesktop};
    line-height: 170%;
    margin-top: 0;
    padding: 24px;
    position: ${o => (o.textPlacementDesktop === 'center' ? 'absolute' : 'relative')};
    text-align: ${o => o.textAlignDesktop || 'center'};
    width: ${o => o.textPlacementDesktop !== 'center' && '50%'};

    > .configurable-width {
      max-width: ${o =>
        o.textContentMaxWidthDesktop
          ? `${o.textContentMaxWidthDesktop}px`
          : o.textPlacementDesktop === 'center'
          ? '500px'
          : '384px'};
    }
  }

  > * {
    margin-left: auto;
    margin-right: auto;
  }
`

export const TextOverlayPlayButton = styled(PlayButton)<{
  textPlacementMobile: PageBannerSectionTextPlacementMobile
}>`
  display: ${o => (o.textPlacementMobile === 'below' ? 'none' : 'inline-block')};
  margin: 22px auto 8px;

  ${fromMd} {
    display: inline-block;
    margin-top: 32px;
  }
`

/* TODO: Fix this auto suppressed ESLint rule */
/* eslint-disable-next-line rulesdir/require-responsive-images */
export const TitleImage = styled.img<{
  titleImageMaxWidthDesktop: number | null
  titleImageMaxWidthMobile: number | null
}>`
  display: block;
  margin: 0 auto 16px;
  width: 100%;

  ${untilMd} {
    max-width: ${o => (o.titleImageMaxWidthMobile ? `${o.titleImageMaxWidthMobile}px` : '78%')};
  }

  ${fromMd} {
    max-width: ${o => (o.titleImageMaxWidthDesktop ? `${o.titleImageMaxWidthDesktop}px` : '50%')};
  }

  ${MobileAssetOverlayWhenBelow} & {
    margin-bottom: 30px;
  }
`

export const TextOverlayTitleImage = styled(TitleImage)<{
  textPlacementMobile: PageBannerSectionTextPlacementMobile
}>`
  display: ${o => (o.textPlacementMobile === 'below' ? 'none' : 'block')};

  ${fromMd} {
    display: block;
  }
`

export const Title = styled(TypeStyle.HeadlineMedium)<{
  textAlignMobile: PageBannerSectionTextAlign | null
  textAlignDesktop: PageBannerSectionTextAlign | null
}>`
  margin-bottom: 16px;
  text-align: ${o => o.textAlignMobile || 'center'};

  ${fromMd} {
    display: block;
    text-align: ${o => o.textAlignDesktop || 'center'};
  }
`

export const Hotspot = styled.a`
  background: white;
  border-radius: 36px;
  bottom: 24px;
  display: flex;
  height: 36px;
  justify-content: center;
  left: 24px;
  position: absolute;
  width: 36px;
  z-index: 101;

  & > svg {
    transform: translateY(100%);
  }
`

export const PlayButtonWrapper = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  ${fromMd} {
    margin-top: 24px;
  }
`
