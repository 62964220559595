import styled from 'styled-components'

import { Accordion, fromMd } from '@syconium/little-miss-figgy'

export const PageSectionAccordionWrapper = styled.div<{ maxWidth: number }>`
  display: flex;
  margin: auto;
  width: 100%;
  max-width: ${o => o.maxWidth && `${o.maxWidth}px`};
  border-top: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  border-bottom: 1px solid ${o => o.theme.color.deprecated.stoneLight};
`

export const PageSectionAccordionHeader = styled(Accordion.Header)`
  padding: 24px 0;

  ${fromMd} {
    padding: 24px 0;
  }
`

export const PageSectionAccordionBody = styled(Accordion.Body)`
  padding-left: 16px;
  padding-right: 16px;
`
