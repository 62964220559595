import React, { useCallback } from 'react'

import {
  Image,
  OpenVideoModalTextButton,
  Plus,
  ResponsiveImage,
  lgEndPixels,
  smEndPixels,
} from '@syconium/little-miss-figgy'
import { userPrefersReducedMotion } from '@syconium/little-miss-figgy/src/lib/utils/prefersReducedMotion'
import { CtaButton } from '@syconium/magnolia/src/brunswick/components/CtaButton'
import { MaxIterationsVideo } from '@syconium/magnolia/src/brunswick/components/MaxIterationsVideo'
import { VideoModal } from '@syconium/magnolia/src/brunswick/components/VideoModal'
import { VimeoVideo } from '@syconium/magnolia/src/brunswick/components/VimeoVideo'
import { PageBannerSectionTitle } from '@syconium/magnolia/src/components/page-sections/PageBannerSection/Title/Title'
import { usePageSectionContext } from '@syconium/magnolia/src/components/page-sections/PageSectionContext'
import { MaybeSpaLinkWrapper } from '@syconium/magnolia/src/lib/adapters/next-routing-service/MaybeSpaLinkWrapper'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { isUppercase } from '@syconium/magnolia/src/lib/utils'
import { InlineVideoPlatforms } from '@syconium/magnolia/src/types/video'

import { MaybeSpaLink } from '../../../lib/adapters/next-routing-service/MaybeSpaLink'
import { useImageLoader } from '../../../lib/hooks/useImageLoader'
import { useResponsiveVideoSrc } from '../../../lib/hooks/useResponsiveVideoSrc'
import { PlayButton } from '../../../lib/shared-styles'
import {
  PageBannerSection as IProps,
  PageBannerSectionTextPlacementDesktop,
  StyledContentItem,
} from '../../../types/graphql/contentful-components/page-banner-section'
import { ContentfulStyledImage } from '../../contentful/ContentfulStyledImage/ContentfulStyledImage'
import { ContentfulStyledText } from '../../contentful/ContentfulStyledText/ContentfulStyledText'

import {
  AssetWrapper,
  Body,
  ButtonsWrapper,
  Eyebrow,
  Hotspot as HotspotLink,
  Links,
  MobileAssetOverlayWhenBelow,
  PageSection,
  Paragraph,
  PlayButtonWrapper,
  StyledContentItemsWrapper,
  TextOverlay,
  TextOverlayPlayButton,
  TextOverlayTitleImage,
  TitleImage,
} from './styles'

const PREFFERED_INLINE_VIDEO_PLATFORM = InlineVideoPlatforms.DEFAULT_PLAYER

const Hotspot: React.FC<{ linkTo: string }> = ({ linkTo }) => {
  return (
    <MaybeSpaLinkWrapper href={linkTo}>
      <HotspotLink href={linkTo}>
        <Plus />
      </HotspotLink>
    </MaybeSpaLinkWrapper>
  )
}

export const BannerImage: React.FC<{
  altText: string
  hotspot: Readonly<{ linkHref1: string }> | null
  imageDesktop: string | null
  imageMobile: string | null
  shouldShowHotspot?: boolean
  textPlacementDesktop: PageBannerSectionTextPlacementDesktop
}> = ({ altText, hotspot, imageDesktop, imageMobile, shouldShowHotspot, textPlacementDesktop }) => {
  const src = imageDesktop ?? imageMobile

  const srcs =
    imageDesktop && imageMobile
      ? {
          sm: imageMobile,
          md: imageDesktop,
        }
      : undefined

  if (!src) return null
  return (
    <>
      <ResponsiveImage
        alt={altText}
        aspectRatios={{
          sm: null,
          md: null,
        }}
        src={src}
        srcs={srcs}
        loading={'lazy'}
        widths={{
          unit: 'vw',
          sm: 100,
          md: textPlacementDesktop === 'center' ? 100 : 50,
        }}
      />

      {shouldShowHotspot && <Hotspot linkTo={hotspot!.linkHref1} />}
    </>
  )
}

export const PageBannerSection: React.FC<IProps> = ({
  ctaStyle,
  backgroundColorDesktop,
  backgroundColorMobile,
  eyebrow,
  hotspot,
  imageDesktop,
  imageMobile,
  inlineVideoDesktop,
  inlineVideoMobile,
  inlineVimeoIdDesktop,
  inlineVimeoIdMobile,
  isFullBleedDesktop,
  linkHref1,
  linkHref2,
  linkText1,
  linkText2,
  modalVideoId,
  modalVideoPlatform,
  name,
  overlayColorDesktop,
  overlayColorMobile,
  paragraph,
  textAlignDesktop,
  textAlignMobile,
  textColorDesktop,
  textColorMobile,
  textContentMaxWidthDesktop,
  textContentMaxWidthMobile,
  textPlacementDesktop,
  textPlacementMobile,
  title,
  titleTextSize,
  titleImage,
  titleImageMaxWidthDesktop,
  titleImageMaxWidthMobile,
  styledContentItems,
  hasVerticalMargin,
  openVideoModalText,
}) => {
  const { contentfulImageLoader } = useImageLoader()
  const { pageSectionIndex: pageSectionIndex } = usePageSectionContext()

  const hasModalVideo = Boolean(modalVideoId && modalVideoPlatform)
  const isTallAssetMobile = Boolean(inlineVideoMobile)
  linkHref1 = linkHref1 ?? ''
  linkHref2 = linkHref2 ?? ''

  const [isVideoModalOpen, setIsVideoModalOpen] = React.useState<boolean>(false)

  const openVideoModal = React.useCallback(() => {
    if (hasModalVideo) setIsVideoModalOpen(true)
  }, [hasModalVideo])

  const closeVideoModal = React.useCallback(() => {
    setIsVideoModalOpen(false)
  }, [])

  const altText = title ?? paragraph ?? ''

  const shouldUseDisplayImages =
    !(inlineVideoDesktop || inlineVimeoIdDesktop) || !(inlineVideoMobile || inlineVimeoIdMobile)

  const video = useResponsiveVideoSrc({
    inlineVideoDesktop,
    inlineVideoMobile,
    inlineVimeoIdDesktop,
    inlineVimeoIdMobile,
    preferredVideoPlatform: PREFFERED_INLINE_VIDEO_PLATFORM,
  })

  const shouldShowHotspot: boolean =
    Boolean(hotspot?.linkHref1) &&
    (textPlacementDesktop === 'right' ||
      textPlacementDesktop === 'left' ||
      textPlacementMobile === 'below')

  const useNewContentFields = !!styledContentItems.length
  const lastContentItem = styledContentItems.at(-1)
  const renderStyledContentItems = useCallback(
    (props: StyledContentItem, index: number, numberOfItems: number): JSX.Element | null => {
      const key: string = `${props.id}-${index}`
      const isFirstStyledTextItem =
        styledContentItems.findIndex(o => o.__typename === 'StyledText') === index

      switch (props.__typename) {
        case 'StyledText':
          return (
            <ContentfulStyledText
              key={key}
              {...props}
              centerFloatContent
              defaultMaxWidth={450}
              defaultBottomMargin={index < numberOfItems - 1 ? 24 : 0}
              additionalCustomContent={
                isFirstStyledTextItem && modalVideoId ? (
                  !openVideoModalText ? (
                    <div className='configurable-width'>
                      <TextOverlayPlayButton
                        {...trackEvent({
                          category: 'page-banner-section',
                          action: 'click open video modal',
                          pageSectionName: title ?? name,
                          pageSectionIndex,
                          correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                        })}
                        onClick={openVideoModal}
                        {...{ textPlacementMobile }}
                      />
                    </div>
                  ) : (
                    <PlayButtonWrapper>
                      <OpenVideoModalTextButton
                        analyticsTracking={trackEvent({
                          category: 'page-banner-section',
                          action: 'click open video modal',
                          pageSectionName: title ?? name,
                          pageSectionIndex,
                          correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                        })}
                        text={openVideoModalText}
                        onClick={openVideoModal}
                      />
                    </PlayButtonWrapper>
                  )
                ) : null
              }
            />
          )
        case 'StyledImage':
          return (
            <ContentfulStyledImage
              key={key}
              {...props}
              transparentBackground
              widths={
                props.maxWidthMobile || props.maxWidthDesktop
                  ? {
                      unit: 'px',
                      sm: props.maxWidthMobile ?? smEndPixels,
                      md: props.maxWidthDesktop ?? lgEndPixels,
                    }
                  : { unit: 'vw', md: textPlacementDesktop === 'center' ? 100 : 50, sm: 100 }
              }
            />
          )
        default:
          return null
      }
    },
    [
      styledContentItems,
      modalVideoId,
      pageSectionIndex,
      openVideoModal,
      name,
      textPlacementMobile,
      textPlacementDesktop,
      imageDesktop,
      title,
      titleImage,
      openVideoModalText,
    ]
  )

  return (
    <PageSection
      isFullBleedDesktop={isFullBleedDesktop}
      hasVerticalMargin={hasVerticalMargin}
      {...trackEvent({
        category: 'page-banner-section',
        action: 'any',
        pageSectionName: title ?? name,
        pageSectionIndex,
        correspondingAsset: imageDesktop ?? titleImage ?? undefined,
      })}
    >
      <Body
        data-testid='page-banner-section-body'
        backgroundColorDesktop={backgroundColorDesktop}
        backgroundColorMobile={backgroundColorMobile}
        textPlacementDesktop={textPlacementDesktop}
        textPlacementMobile={textPlacementMobile}
      >
        <AssetWrapper
          isFullBleedDesktop={isFullBleedDesktop}
          isTallAssetMobile={isTallAssetMobile}
          overlayColorDesktop={overlayColorDesktop}
          overlayColorMobile={overlayColorMobile}
          textPlacementDesktop={textPlacementDesktop}
          textPlacementMobile={textPlacementMobile}
        >
          <MobileAssetOverlayWhenBelow {...{ textPlacementMobile }}>
            {titleImage && (
              <Image
                alt={altText}
                imageComponent={TitleImage}
                loading='lazy'
                src={contentfulImageLoader({ src: titleImage, quality: 90 })}
              />
            )}
            {hasModalVideo ? (
              !openVideoModalText ? (
                <PlayButton
                  onClick={openVideoModal}
                  {...trackEvent({
                    category: 'page-banner-section',
                    action: 'click open video modal',
                    pageSectionName: title ?? name,
                    pageSectionIndex,
                    correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                  })}
                />
              ) : (
                <PlayButtonWrapper>
                  <OpenVideoModalTextButton
                    analyticsTracking={trackEvent({
                      category: 'page-banner-section',
                      action: 'click open video modal',
                      pageSectionName: title ?? name,
                      pageSectionIndex,
                      correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                    })}
                    text={openVideoModalText}
                    onClick={openVideoModal}
                  />
                </PlayButtonWrapper>
              )
            ) : null}
          </MobileAssetOverlayWhenBelow>

          {video?.platform === InlineVideoPlatforms.DEFAULT_PLAYER && (
            <MaxIterationsVideo
              autoPlay
              isLazy
              loop
              maxIterations={5}
              muted
              playsInline
              src={video.src}
            />
          )}

          {video?.platform === InlineVideoPlatforms.VIMEO && (
            <VimeoVideo
              autoplay={!userPrefersReducedMotion}
              includePauseButton={true}
              lazy
              thumbnail={
                <BannerImage
                  altText={altText}
                  hotspot={hotspot}
                  imageDesktop={imageDesktop}
                  imageMobile={imageMobile}
                  shouldShowHotspot={shouldShowHotspot}
                  textPlacementDesktop={textPlacementDesktop}
                />
              }
              videoSrc={video.src}
            />
          )}

          {shouldUseDisplayImages ? (
            <BannerImage
              {...{
                altText,
                hotspot,
                imageDesktop,
                imageMobile,
                shouldShowHotspot,
                textPlacementDesktop,
              }}
            />
          ) : null}
        </AssetWrapper>

        {useNewContentFields ? (
          <StyledContentItemsWrapper
            textPlacementDesktop={textPlacementDesktop}
            textPlacementMobile={textPlacementMobile}
            endsInLink={
              lastContentItem?.__typename === 'StyledText' && lastContentItem?.links.length > 0
            }
          >
            {styledContentItems.map((item, index, array) =>
              renderStyledContentItems(item, index, array.length)
            )}
          </StyledContentItemsWrapper>
        ) : (
          <TextOverlay
            {...{
              textColorDesktop,
              textColorMobile,
              textPlacementDesktop,
              textPlacementMobile,
              textContentMaxWidthDesktop,
              textContentMaxWidthMobile,
              textAlignMobile,
              textAlignDesktop,
            }}
          >
            {eyebrow && (
              <Eyebrow className='configurable-width' {...{ textPlacementMobile }}>
                {eyebrow}
              </Eyebrow>
            )}

            {titleImage && (
              <TextOverlayTitleImage
                alt={altText}
                loading='lazy'
                src={titleImage}
                {...{ textPlacementMobile, titleImageMaxWidthDesktop, titleImageMaxWidthMobile }}
              />
            )}

            {title && (
              <PageBannerSectionTitle
                className='configurable-width'
                titleTextSize={titleTextSize}
                isUppercase={isUppercase(title)}
                includeBottomMargin={true}
                {...{ textAlignMobile, textAlignDesktop }}
              >
                {title}
              </PageBannerSectionTitle>
            )}

            {paragraph && <Paragraph className='configurable-width'>{paragraph}</Paragraph>}

            {modalVideoId && (
              <div className='configurable-width'>
                {!openVideoModalText ? (
                  <TextOverlayPlayButton
                    {...trackEvent({
                      category: 'page-banner-section',
                      action: 'click open video modal',
                      pageSectionName: title ?? name,
                      pageSectionIndex,
                      correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                    })}
                    onClick={openVideoModal}
                    {...{ textPlacementMobile }}
                  />
                ) : (
                  <PlayButtonWrapper>
                    <OpenVideoModalTextButton
                      analyticsTracking={trackEvent({
                        category: 'page-banner-section',
                        action: 'click open video modal',
                        pageSectionName: title ?? name,
                        pageSectionIndex,
                        correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                      })}
                      text={openVideoModalText}
                      onClick={openVideoModal}
                    />
                  </PlayButtonWrapper>
                )}
              </div>
            )}

            {ctaStyle === 'link' && linkHref1 && linkText1 && (
              <Links {...{ textAlignMobile, textAlignDesktop }} className='configurable-width'>
                <MaybeSpaLink
                  href={linkHref1}
                  {...trackEvent({
                    category: 'page-banner-section',
                    action: 'click cta 1',
                    label: linkText1,
                    value: linkHref1,
                    pageSectionName: title ?? name,
                    pageSectionIndex,
                    correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                  })}
                >
                  {linkText1}
                </MaybeSpaLink>
                {linkHref2 && linkText2 && (
                  <MaybeSpaLink
                    href={linkHref2}
                    {...trackEvent({
                      category: 'page-banner-section',
                      action: 'click cta 2 ',
                      label: linkText2,
                      value: linkHref2,
                      pageSectionName: title ?? name,
                      pageSectionIndex,
                      correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                    })}
                  >
                    {linkText2}
                  </MaybeSpaLink>
                )}
              </Links>
            )}
            {ctaStyle === 'button' && linkHref1 && linkText1 && (
              <ButtonsWrapper>
                <MaybeSpaLinkWrapper
                  href={linkHref1}
                  {...trackEvent({
                    category: 'page-banner-section',
                    action: 'click cta 1',
                    label: linkText1,
                    value: linkHref1,
                    pageSectionName: title ?? name,
                    pageSectionIndex,
                    correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                  })}
                >
                  <CtaButton as='a' href={linkHref1}>
                    {linkText1}
                  </CtaButton>
                </MaybeSpaLinkWrapper>
                {linkHref2 && linkText2 && (
                  <MaybeSpaLinkWrapper
                    href={linkHref2}
                    {...trackEvent({
                      category: 'page-banner-section',
                      action: 'click cta 2 ',
                      label: linkText2,
                      value: linkHref2,
                      pageSectionName: title ?? name,
                      pageSectionIndex,
                      correspondingAsset: imageDesktop ?? titleImage ?? undefined,
                    })}
                  >
                    <CtaButton as='a' href={linkHref2}>
                      {linkText2}
                    </CtaButton>
                  </MaybeSpaLinkWrapper>
                )}
              </ButtonsWrapper>
            )}
          </TextOverlay>
        )}

        {hasModalVideo && (
          <VideoModal
            isOpen={isVideoModalOpen}
            onClose={closeVideoModal}
            videoId={modalVideoId}
            videoPlatform={modalVideoPlatform}
          />
        )}
      </Body>
    </PageSection>
  )
}
