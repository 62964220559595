import React from 'react'

export const ScrollDownArrow: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <svg className={className} width='25' height='15' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.893 1.5L12.5 12.107 23.107 1.5'
        stroke='currentColor'
        strokeWidth='3'
        fill='none'
      />
    </svg>
  )
}
