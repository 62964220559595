import styled from 'styled-components'

import { TypeStyle, fromMd, zIndexForeground } from '@syconium/little-miss-figgy'
import { IconBadge, IconSize } from '@syconium/magnolia/src/brunswick/components/IconBadge'
import { iconBadgeStyles } from '@syconium/magnolia/src/brunswick/components/IconBadge/styles'
import { IconBadgePlacement } from '@syconium/magnolia/src/types/graphql'

export const Body = styled.main``

export const SectionTitle = styled(TypeStyle.HeadlineExtraSmall)`
  color: ${o => o.theme.color.deprecated.obsidian};
  text-align: center;

  ${fromMd} {
    font-size: 18px;
  }
`

export const DetailsSectionSidePaddedSection = styled.div`
  margin: 0 ${o => o.theme.dimensions.layout.sidePadding.md}px;

  ${fromMd} {
    margin: 0;
  }
`

export const StyledIconBadge = styled(IconBadge)<{
  iconBadgePlacement?: IconBadgePlacement
  size: IconSize
  shouldDisplay?: boolean
}>`
  ${o => iconBadgeStyles[o.iconBadgePlacement ?? 'top left'][o.size]}
  margin: 8px 0;
  position: absolute;
  z-index: ${zIndexForeground};

  ${fromMd} {
    margin: 16px 8px;
  }

  transition: opacity 0.2s ease-in;
  opacity: ${o => (o.shouldDisplay ? 1 : 0)};
`

export const ReviewsPlaceholder = styled.div`
  min-height: 50vh;
  min-height: clamp(25svh, 250px, 33svh);
`
