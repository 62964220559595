import { FC } from 'react'

import { Accordion } from '@syconium/little-miss-figgy'
import { AccordionTitle } from '@syconium/magnolia/src/components/pages/products/ProductPage/DetailsSection/ProductDetailsAccordionSection/styles'

import { RichTextSectionFragmentFragment } from '../../../../__generated__/graphql/catalog/graphql'
import {
  AccordionView as AccordionViewType,
  RawHtmlSection as RawHtmlSectionType,
} from '../../../../types/graphql'
import { RawHtmlSection } from '../RawHtmlSection'
import { RichTextSection } from '../RichTextSection'

import {
  PageSectionAccordionBody,
  PageSectionAccordionHeader,
  PageSectionAccordionWrapper,
} from './styles'

const renderAccordionContent = (
  props: RichTextSectionFragmentFragment | RawHtmlSectionType,
  index: number
): JSX.Element | null => {
  const key: string = `${props?.id}-${index}`
  switch (props?.__typename) {
    case 'RichTextSection':
      return <RichTextSection key={key} {...props} />
    case 'RawHtmlSection':
      return <RawHtmlSection key={key} {...props} />

    default:
      return null
  }
}

export const AccordionView: FC<AccordionViewType> = ({ maxWidth, sections }) => {
  return (
    <PageSectionAccordionWrapper maxWidth={maxWidth}>
      <Accordion
        variant='light'
        icon='plus'
        ariaLabel='Detail Section'
        isTransparent
        showBorder
        multiOpen
        withAnimation
      >
        {sections.map((section, index) => (
          <Accordion.Section index={index}>
            <PageSectionAccordionHeader>
              <AccordionTitle textTransform='uppercase' asTag='p'>
                {section.title}
              </AccordionTitle>
            </PageSectionAccordionHeader>
            <PageSectionAccordionBody>
              <div>{renderAccordionContent(section.content, index)}</div>
            </PageSectionAccordionBody>
          </Accordion.Section>
        ))}
      </Accordion>
    </PageSectionAccordionWrapper>
  )
}
