import styled from 'styled-components'

import {
  ColorSwatch,
  TypeStyle,
  fromMd,
  mdEndPixels,
  pageLayoutMaxWidth,
} from '@syconium/little-miss-figgy'
import {
  sectionGapDesktop,
  sectionGapMobile,
  sectionSideGapDesktop,
} from '@syconium/magnolia/src/brunswick/components/page-sections/styles'

import { TitleAlignment } from '../../../types/graphql/page-view'

export const Body = styled.div`
  margin: 0 auto;
  padding-bottom: ${sectionGapMobile}px;

  ${fromMd} {
    max-width: ${pageLayoutMaxWidth};
    padding-bottom: ${sectionGapDesktop}px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${fromMd} {
    flex-direction: row;
    gap: 8px;
  }
`

export const Heading = styled.div`
  padding: 0 ${o => o.theme.dimensions.layout.sidePadding.md}px;
  margin-bottom: 24px;
  min-height: 25px;
  display: flex;
  column-gap: ${o => o.theme.spacing(5)};
  row-gap: ${o => o.theme.spacing(4)};
  flex-direction: column;

  ${fromMd} {
    padding-left: ${sectionSideGapDesktop}px;
    min-height: 42px;
    flex-direction: row;
  }
`

export const TitleWrapper = styled.div`
  flex: 1;
`

export const Title = styled(TypeStyle.HeadlineSmall)<{
  titleAlignmentDesktop?: TitleAlignment
  titleAlignmentMobile?: TitleAlignment
}>`
  text-align: ${o => o.titleAlignmentMobile ?? 'left'};

  ${fromMd} {
    max-width: ${mdEndPixels}px;
    text-align: ${o => o.titleAlignmentDesktop ?? 'left'};
  }
`

export const Subtitle = styled(TypeStyle.BodyDefault)<{
  titleAlignmentDesktop?: TitleAlignment
  titleAlignmentMobile?: TitleAlignment
}>`
  margin-top: 1em;
  text-align: ${o => o.titleAlignmentMobile ?? 'left'};

  ${fromMd} {
    max-width: ${o => o.titleAlignmentDesktop === 'left' && '80%'};
    text-align: ${o => o.titleAlignmentDesktop ?? 'left'};
  }
`

export const LinkWrapper = styled.div`
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: ${o => o.theme.spacing(4)};
`

export const AssetWrapper = styled.div`
  img,
  video {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  ${fromMd} {
    padding-left: 120px;
    z-index: 1;
    width: 50%;
  }
`

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  ${fromMd} {
    flex-direction: row;
    gap: 8px;
    width: 100%;
  }
`

export const ColorFilters = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  ${fromMd} {
    padding: 0 120px 30px 120px;
  }
  padding: 0 16px 16px 16px;
  align-items: center;
`

export const ActiveName = styled(TypeStyle.BodyLarge)`
  color: #707070;
  font-style: italic;
`
export const ColorFilterButton = styled(ColorSwatch)``
